import React from "react";

export default {
    backend: {
        host: "https://the-politics-of-covid-19.com/search/api",
        index: "demos"
    },
    header: {
        logo: null,
        title: "Demos Research Archive",
    intro: <span>{"Browse and search all the pieces featured in the bi-weekly Research Digests since inception as well as a much larger selection of relevant pieces across text, video and audio."}</span>,
    }
};